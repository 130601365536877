<template>
  <div>
    <van-nav-bar title="设备报修页面" fixed border/>
    <div class="copy-box">
      <div class="copy">
        <div class="device">
          <img src="@/asset/image/number.png" alt="">
          <span id="device">{{ deviceId }}</span>
        </div>
        <van-button type="primary" @click="copy(deviceId)" style="margin-right: 10px">复制</van-button>
      </div>
      <div class="preview">
        <van-button type="primary" icon="setting-o"  @click="previewInformation" size="small">完善信息</van-button>
        <span @click="previewDetail">查看设备详情 <van-icon name="arrow" /></span>
      </div>
    </div>
    <div class="nav-item-group">
      <div class="nav-item nav-item-create-workorder" @click="onCreateWorkorderClick">
        <div class="item-card">
          <img src="@/asset/image/repair.jpg" alt="发起报修图标">
          <div class="item-content">
            <span>发起报修</span>
            <span class="english">Request repair service</span>
          </div>
        </div>
      </div>

      <div class="nav-item nav-item-query-workorder" @click="onQueryProgressingWorkorderClick">
        <div class="item-card">
          <img src="@/asset/image/progress.jpg" alt="查看报修进度图标">
          <div class="item-content">
            <span>查看报修进度</span>
            <span class="english">Repair progress</span>
          </div>
        </div>

      </div>

      <div class="details nav-item nav-item-query-details" @click="onQueryDeviceDetailsClick">
        <div class="item-card">
          <img src="@/asset/image/record.jpg" alt="查看维护记录图标">
          <div class="item-content">
            <span>查看维护记录</span>
            <span class="english">Repair history</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {copy} from "@/plugin/clipboard";
import {Toast} from "vant";
import {isCreate, requestWorkorderProgressing, requestWorkorderUnconverted} from "../../api/workorder";
import {queryDevice} from "../../api/device";
import dd from "gdt-jsapi";

onBeforeMount(validationUrlParamStrictly);
const urlParam = getUrlParam();
const router = useRouter();

const deviceId = ref(urlParam.id);
const tenant = ref(urlParam.tenant);
const isEmptyId = ref(false);
const query = getUrlParam();

const onCreateWorkorderClick = () => {
  if (!!isEmptyId.value) {
    Toast.fail({
      message: "当前设备ID未录入，已为您跳转到发起报修工单页面",
      forbidClick: true
    });
    query.origin = "prepare-empty-device";
    router.push({path: "/workorder/prepare", query});
    return;
  }
  query.conservative = "sure";
  router.push({query: query, path: "/device/confirm"});
};
const onQueryProgressingWorkorderClick = () => {
  isCreate(deviceId.value).then(() => {
    Toast({
      message: "该设备暂无正在进行的工单",
      forbidClick: true
    });
  }).catch(() => {
    requestWorkorderProgressing(query.id).then(res => {
      if (!!res.data && res.data.length > 0) {
        query.workorderId = res.data[0].id;
        router.push({
          query,
          path: "/workorder"
        });
      } else {
        requestWorkorderUnconverted(query.id).then(res => {
          query.workorderId = res.data[0].id;
          router.push({
            query,
            path: "/unassigned-workorder"
          });
        });
      }
    }).catch(err => {
      Toast({
        message: "无法查询！",
        forbidClick: true
      });
    });
  });
};
const onQueryDeviceDetailsClick = () => router.push({query: getUrlParam(), path: "/device/record"});
const previewDetail = () => router.push({query: getUrlParam(), path: "/device"});
const previewInformation = () =>router.push({query: getUrlParam(), path: "/device/perfect-information"});
const checkEquipment = () => {
  queryDevice(query.id).then(res => {
    let {customerId} = res.data;
    if(!customerId){
      Toast({
        message: "请完善客户信息！",
        forbidClick: true
      });
      previewInformation()
    }
  }).catch(err => {
    if (err.status === 40400) {
      isEmptyId.value = true;
    }
  });
};

onMounted(()=>{
  dd.showTitleBar()
  checkEquipment()
});
</script>

<style scoped>
.nav-item-group {
  height: calc(100vh - 156px);
  background-color: rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.copy-box {
  margin-top: 46px;
  width: 100%;
  padding-top: 15px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.copy {
  display: flex;
  justify-content: space-between;
}

.preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #999999;
  margin: 15px;
  font-weight: bold;
  font-family: "宋体";
  align-items: center;
}
.preview .wsxxbut{
  color: #ffffff;
  background: #3E69FB;
  padding: 7px;
  border-radius: 5px;
}

.device {
  background: #F3F7FD;
  flex: 1;
  height: 45px;
  line-height: 45px;
  margin: 0 15px;
}

.device img {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
}

.nav-item {
  width: 94%;
  padding: 36px 0;
  letter-spacing: 8px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background-color: #fff;
}

.nav-item img {
  width: 112px;
  margin-right: 16px;
  display: inline-block;
  vertical-align: middle;
}

.nav-item-create-workorder {
  display: flex;
  justify-content: start;
  color: #009C86;
}

.nav-item-query-workorder {
  display: flex;
  justify-content: start;
  color: #BA68C8;
}

.nav-item-query-details {
  display: flex;
  justify-content: start;
  color: #3E69FB;
}

.item-content {
  display: flex;
  font-size: 26px;
  letter-spacing: 2px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.english {
  width: 100%;
  margin-left: 3px;
  margin-top: 3px;
  display: inline-block;
  text-align: left;
  font-size: 12px;
  color: #ccc;
  letter-spacing: 0
}

.item-card {
  display: flex;
  justify-content: center;
  margin-left: 30px
}
</style>
